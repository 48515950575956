<div class="container" style="width: 650px">
  <div style="text-align: center; margin: 20px 0">
    <img src="assets/brautpaar.png" style="max-width: 50%; height: auto">
  </div>

  <div class="my-3">
    <h3 style="font-family: 'Ms Madi', cursive; font-size: 35px; margin: 40px 0 20px 0">Jil &amp; Alex "Günna" Maibach's Wedding 2022</h3>
    <h4 style="font-family: 'Roboto', cursive; font-size: 15px; font-weight: 600">Teile jetzt deine Bilder mit dem Brautpaar!</h4>
    <p style="font-family: 'Roboto', sans-serif; font-size: 15px; font-weight: 300; margin-bottom: 30px">Die Bilder sind nur für das Brautpaar sichtbar.<br>Die beiden werden im Anschluss eine Auswahl für alle bereitstellen.</p>
  </div>

  <app-upload-images></app-upload-images>
</div>
